<template>
  <b-navbar toggleable="xl" type="dark" sticky>
    <!-- Right aligned nav items -->
    <b-navbar-nav class="mr-auto">
        <span class="header_table" v-if="companyData.tableNumber">
          {{ "Tisch" | translate }} {{ tableNumber }}
        </span>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-item>
        <CartWidget />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import CartWidget from "@/components/shopping-cart/CartWidget";

export default {
  name: "app-header",
  data() {
    return {
      min_orderValue: 0,
    };
  },
  computed: {
    ...mapGetters(["companyData", "total"]),
    tableNumber() {
      if(this.companyData.tableNumber){
        return this.companyData.tableNumber;
      }else{
        return "";
      }
    },
  },
  components: {
    CartWidget,
  },
  created() {
    this.min_orderValue = this.companyData.minimumOrderValue;
  },
  methods: {
    clearStore() {
      window.localStorage.clear();
      window.location.href =
        "/stores/?lang=" + this.companyData.defaultLanguage;
    },
    get_lang() {
      return Vue.i18n.locale();
    },
    set_lang(value) {
      Vue.i18n.set(value);
    },
  },
};
</script>

<style scoped>
.min_orderValue {
  color: red;
}
.dropdown-item img {
  width: 50px;
  padding: 5px;
}
.submenu {
  position: relative;
  margin-left: 100%;
}
.icon-wrapper {
  color: #ffffff;
}
</style>
