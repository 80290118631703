<template>
  <footer id="sticky-footer">
    <div class="row" v-if="!$route.meta.hideNavbarTop">
      <div class="col-lg-12 text-center">
        <b-button-group size="sm">
          <b-button @click="home()">
            <BaseIcon name="home" width="16" height="16" />
          </b-button>
          <b-button @click="store()" style="font-size: 12px">
            {{ "Über Uns" | translate }}
          </b-button>
          <b-button @click="legal()" style="font-size: 12px">
            {{ "Impressum" | translate }}
          </b-button>
          <b-button @click="privacy()" style="font-size: 12px">
            {{ "Datenschutz" | translate }}
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="w-100 text-center">
        <span style="font-size: 12px"
          >Copyright &copy; 2020 Xeno-Data GmbH</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
  methods: {
    store() {
      if (this.$route.path !== "/store") this.$router.push({ path: "/store" });
    },
    legal() {
      if (this.$route.path !== "/legal") this.$router.push({ path: "/legal" });
    },
    privacy() {
      if (this.$route.path !== "/privacy")
        this.$router.push({ path: "/privacy" });
    },
    home() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped></style>
